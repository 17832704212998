<!-- 测评记录 -->
<template>
  <div class="record">
    <van-nav-bar
      title="测评结果"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div class="recordList" v-for="(item, index) in recordList" :key="index">
      <div class="recordItem">
        <div>测评</div>
        <div>{{ item.create_time }}</div>
        <div class="baogaoanniu">
          <div @click="totestresult(item)">查看报告</div>
          <div
            class="duibi"
            @click="tocomparison(item)"
            v-if="item.task_info_id == 116"
          >
            对比报告
          </div>
        </div>
      </div>
    </div>
    <div class="nullData" v-if="recordList == 0">
      <div class="nullDatactx">
        <img
          src="https://lesson.iapeap.com/images/EmotionalValue/kongbox.png"
          alt=""
        />
        <div>暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import { record, memberLog } from "../../api/login";
export default {
  data() {
    return {
      recordList: [],
      starttime: "", //  进入页面时间
      endtime: "", //  退出页面时间
      time: "", //   页面累积时间
    };
  },
  created() {
    this.getrecord();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getrecord() {
      let data = {
        userId: window.localStorage.getItem("userId"),
      };
      record(data).then((res) => {
        this.recordList = res.data;
      });
    },

    //去测评报告
    totestresult(item) {
      console.log("查看报告拿到的id", item.id);
      this.$router.push({
        path: "/test/testresult",
        query: { id: item.id },
      });
    },
    tocomparison(item) {
      console.log("查看报告拿到的id", item.id);
      this.$router.push({
        path: "/HouCe/comparison",
        query: { id: item.id },
      });
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.time++;
      // console.log("页面累积浏览时间是：",this.time,"秒")
    }, 1000); // 每秒触发一次
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    this.starttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("进入页面的系统时间是：", this.starttime);
  },
  beforeDestroy() {
    clearInterval(this.timer); // 清除定时器
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    this.endttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("离开页面的系统时间是", this.endttime);

    //把参数传给后端接口，starttime、endttime、time
    let data = {
      startTime: this.starttime,
      endTime: this.endttime,
      userId: window.localStorage.getItem("userId"),
      pageName: "测评结果记录",
      timeStamp: this.time,
    };
    memberLog(data).then((res) => {});
  },
};
</script>

<style lang="less">
// 空数据样式
.nullData {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .nullDatactx {
    color: #464646;
    img {
      width: 200px;
    }
    text-align: center;
  }
}

.navbar {
  background-color: #ededed;
}
/deep/ .van-nav-bar .van-icon {
  color: #464646;
}
.record {
  .recordList {
    padding: 20px 20px;
    box-sizing: border-box;
    .recordItem {
      min-height: 48px;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
      border-radius: 8px;
      color: #72acf4;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2px 25px;
      font-size: 14px;
    }
  }
  .baogaoanniu {
    line-height: 25px;
  }
  .duibi {
    color: orange;
  }
}
</style>
